import React, { useEffect, useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";

// Components
import AddButton from "../../../components/AddButton";
// Interface
import { UserTypes } from "../../../data/chat";

// Component
import ChatUser from "./ChatUser";
import { getActiveDep, getLoggedinUser, setActiveDep } from "../../../api/apiCore";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

interface DirectMessagesProps {
  users?: { conversations?: UserTypes[], departmentUnreadCounts: Record<string, number> };
  openAddContact: () => void;
  selectedChat: string | number;
  onSelectChat: (id: number | string) => void;
  onSelectDep: (roleId: string | null) => void;
  status: string ;
  setStatus: React.Dispatch<React.SetStateAction<"open" | "close">>;
}

const DirectMessages = ({
                          users,
                          openAddContact,
                          selectedChat,
                          onSelectChat,
                          onSelectDep,
                          status,
                          setStatus,
                        }: DirectMessagesProps) => {
  const [activeRole, setActiveDepState] = useState<string | null>(getActiveDep());
  const authUser = getLoggedinUser();
  let roles: { id: string; name: string }[] = [];
  const [isLoading, setIsLoading] = useState(false);
  if (authUser) {
    try {
      roles = authUser.user.roles || [];
    } catch (error) {
      console.error("Error parsing authUser cookie:", error);
    }
  }
  const handleStatusChange = (value: "open"| "close") => {
    if (value !== status){
      setIsLoading(true);
      setStatus(value);
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    }
  };
  const handleRoleClick = (roleId: string | null) => {
    setActiveDep(roleId);
    setActiveDepState(roleId);
    if (roleId !== activeRole) {
      onSelectDep(roleId);
    }
  };

  useEffect(() => {
    setActiveDepState(getActiveDep());
  }, []);
  const profileDetails = Cookies.get('authUser');
  const data = profileDetails ? JSON.parse(profileDetails) : null;

// Check if user roles contain "Admin"
  const isAdmin = data?.user?.roles?.some((role: {name:string,id:string}) => role.name === "Admin");

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  // Track the latest unread message timestamps
  const [lastMessageTimestamps, setLastMessageTimestamps] = useState<Record<string, string>>({});

  useEffect(() => {
    if (!users?.conversations) return;

    users.conversations.forEach((user) => {
      const { id, meta } = user;
      const latestMessageTime = meta?.latestMessageTime || null;
      const unreadMessages = meta?.unRead || 0;

      if (unreadMessages > 0 && latestMessageTime) {
        // If it's a new message (compared to the last stored timestamp), show a notification
        if (!lastMessageTimestamps[id] || latestMessageTime > lastMessageTimestamps[id]) {
          toast.info(`${user.name} sent a new message.`);
          setLastMessageTimestamps((prev) => ({ ...prev, [id]: latestMessageTime }));
        }
      }
    });
  }, [users?.conversations]); // Runs when conversations update

  // @ts-ignore
  return (
    <>
      <div className="d-flex align-items-center px-4 mt-5 mb-2">
        <div className="flex-grow-1">
          <h4
            className="mb-0 font-size-11 text-muted text-uppercase cursor-pointer"
            onClick={() => handleRoleClick(null)}
          >
            Departments
          </h4>
        </div>
      </div>
      <div className="px-4">
        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
          <DropdownToggle
            caret
            className="w-100 text-start"
            style={{ backgroundColor: "rgb(78, 172, 109)" }}
          >
            {roles.length > 0
              ? roles.find(role => role.id === activeRole)?.name ||
                "Select Department"
              : "No roles available"}
          </DropdownToggle>
          <DropdownMenu className="w-100">
            {roles.length > 0 ? (
              roles.map(role => (
                <DropdownItem
                  key={role.id}
                  className={`${role.id === activeRole ? "fw-bold text-primary" : ""}`}
                  onClick={() => handleRoleClick(role.id)}
                  style={{ cursor: "pointer" }}
                >
                  {role.name}
                  {users?.departmentUnreadCounts?.[role.id] ? (
                    <span className="badge bg-danger ms-2">
                      {users.departmentUnreadCounts[role.id]}
                    </span>
                  ) : null}
                  {role.id === activeRole && <span className="ms-2">✅</span>}
                </DropdownItem>
              ))
            ) : (
              <DropdownItem className="text-muted">No roles found</DropdownItem>
            )}
          </DropdownMenu>
        </Dropdown>
      </div>

      {isAdmin && (
        <>
          <div className="d-flex align-items-center px-4 mt-3">
            <div className="flex-grow-1">
              <h4 className="mb-0 font-size-11 text-muted text-uppercase">
                Chat Status
              </h4>
            </div>
          </div>

          <div className="d-flex justify-content-center mt-2 mx-4 border-bottom">
            <button
              className={`py-2 border-top border-start border-end text-center ${
                status === "close"
                  ? "bg-light text-dark border-light flex-grow-2"
                  : "bg-primary text-white border-primary flex-grow-1"
              } rounded-top fw-bold`}
              onClick={() => handleStatusChange("open")}
              disabled={isLoading}
              style={{ marginBottom: "-1px", transition: "all 0.3s ease-in-out" }}
            >
              {isLoading && status === "open" ? "Updating..." : "Open"}
            </button>
            <button
              className={`py-2 border-top border-start border-end text-center ${
                status === "open"
                  ? "bg-light text-dark border-light flex-grow-2"
                  : "bg-primary text-white border-primary flex-grow-1"
              } rounded-top fw-bold`}
              onClick={() => handleStatusChange("close")}
              disabled={isLoading}
              style={{ marginBottom: "-1px", transition: "all 0.3s ease-in-out" }}
            >
              {isLoading && status === "close" ? "Updating..." : "Closed"}
            </button>
          </div>

        </>
      )}

      <div className="d-flex align-items-center px-4 mt-3 mb-2">
        <div className="flex-grow-1">
          <h4 className="mb-0 font-size-11 text-muted text-uppercase">
            Direct Messages
          </h4>
        </div>
        <div className="flex-shrink-0">
          <div id="new-message" title="New Message">
            <AddButton onClick={openAddContact} />
          </div>
        </div>
      </div>

      <div className="chat-message-list">
        <ul className="list-unstyled chat-list chat-user-list">
          {(users?.conversations || []).map((user: UserTypes, key: number) => (
            <ChatUser
              user={user}
              key={key}
              selectedChat={selectedChat}
              onSelectChat={onSelectChat}
            />
          ))}
        </ul>
      </div>
    </>
  );
};

export default DirectMessages;
