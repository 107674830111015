import { format, isToday, isYesterday } from "date-fns";

const formateDate = (date: any, formate?: string) => {
  const dateObj = new Date(date);
  const dateFormat = formate ? formate : "MM/dd/yyyy";
  const formattedDate = format(dateObj, dateFormat);
  return formattedDate;
};


const formatMessageDate = (date: string | number | Date) => {
  const dateObj = new Date(date);

  if (isToday(dateObj)) {
    return format(dateObj, "hh:mma"); // Show time for today's messages
  } else if (isYesterday(dateObj)) {
    return "Yesterday"; // Show "Yesterday" if the message was from yesterday
  } else {
    return format(dateObj, "dd/MM/yyyy"); // Show date for older messages (UK format)
  }
};

const formatMessageHistoryDate = (date: string | number | Date) => {
  const dateObj = new Date(date);

  if (isToday(dateObj)) {
    return "Today";
  } else if (isYesterday(dateObj)) {
    return "Yesterday";
  } else {
    return format(dateObj, "EEEE, dd/MM/yyyy"); // Example: "Monday, 11/03/2025"
  }
};

export { formatMessageDate, formateDate, formatMessageHistoryDate };
